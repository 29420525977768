<template>
  <div class="logo">
    <img src="@\assets\imgs\NUL_Logo_Standalone.png" alt="logo" />
    <img
      src="@\assets\imgs\Urban-Empowerment-Fund-2020-cmyk@2x.png"
      alt="logo"
    />
  </div>
  <hr />
  <div class="review">
    <h1 class="my-3 d-flex justify-content-center">Application Summary</h1>

    <h2 class="my-3 subHeader">Business Profile</h2>

    <div class="keep-together">
      <LabelValue :label="'Grant Number'">
        <template v-slot:prefix>
          <strong> {{ loanApplicationData.loanNumber }} </strong>
        </template>
      </LabelValue>

      <LabelValue
        :label="label.BusinessLabel.status"
        :Value="loanApplicationData.applicationStatus"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelBusinessName"
        :Value="loanApplicationData.loanBusinessDetails.businessName"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelDoingBusiness"
        :Value="loanApplicationData.loanBusinessDetails.dba"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelBusinessAddress"
        :Value="loanApplicationData.loanBusinessDetails.address"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelBusinessEmail"
        :Value="loanApplicationData.loanBusinessDetails.emailAddress"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelBusinessPhoneNo"
        :Value="loanApplicationData.loanBusinessDetails.phoneNumber"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelBusinessUrl"
        :Value="loanApplicationData.loanBusinessDetails.url"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelZipCode"
        :Value="loanApplicationData.loanBusinessDetails.zip"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelCity"
        :Value="loanApplicationData.loanBusinessDetails.city"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelState"
        :Value="loanApplicationData.loanBusinessDetails.stateName"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelEIN"
        :Value="loanApplicationData.loanBusinessDetails.ein"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelDUNS"
        :Value="loanApplicationData.loanBusinessDetails.duns"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelAffiliate"
        :Value="loanApplicationData.loanBusinessDetails.affiliateName"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelSICCode"
        :Value="loanApplicationData.loanBusinessDetails.siC_Name"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelNAICSCode"
        :Value="loanApplicationData.loanBusinessDetails.naicsCode"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelBusinessType"
        :Value="loanApplicationData.loanBusinessDetails.businessTypeName"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelIndustry"
        :Value="loanApplicationData.loanBusinessDetails.industryTypeName"
      />

      <LabelValue
        :label="label.BusinessLabel.LabelBusinessStartDate"
        :Value="loanApplicationData.loanBusinessDetails.startDate"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelNoOfYearsBusiness"
        :Value="loanApplicationData.loanBusinessDetails.numberOfYearsInBusiness"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelNoOfEmployess"
        :Value="loanApplicationData.loanBusinessDetails.employeeStrength"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelAccountNo"
        :Value="loanApplicationData.loanBusinessDetails.bankAccountNumber"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelRoutingNo"
        :Value="loanApplicationData.loanBusinessDetails.bankRoutingNumber"
      />
      <LabelValue
        :label="label.BusinessLabel.LabelNote"
        :Value="loanApplicationData.loanBusinessDetails.comment"
      />
    </div>
    <div class="keep-together">
      <h2 class="my-3 subHeader">Owner Profile</h2>
      <div v-for="owner in loanApplicationData.businessOwners" :key="owner">
        <LabelValue
          :label="label.ownerLabel.LabelOwnerName"
          :Value="owner.businessOwnerName"
        />
        <LabelValue
          :label="label.ownerLabel.LabelOwnership"
          :Value="owner.ownedPercentage"
        />
        <LabelValue
          :label="label.ownerLabel.LabelGender"
          :Value="owner.genderName"
        />
        <LabelValue
          :label="label.ownerLabel.LabelRace"
          :Value="owner.raceName"
        />
        <LabelValue
          :label="label.ownerLabel.LabelEthnicity"
          :Value="owner.ethnicityName"
        />
        <LabelValue
          :label="label.ownerLabel.LabelVeteran"
          :Value="owner.veteranName"
        />

        <LabelValue
          :label="label.ownerLabel.LabelOwnerEmail"
          :Value="owner.emailAddress"
        />
        <LabelValue
          :label="label.ownerLabel.LabelOwnerPhoneNo"
          :Value="owner.phoneNumber"
        />
      </div>
    </div>
    <div class="keep-together">
      <h2 class="my-3 subHeader">Funding Application</h2>

      <div>
        <LabelValue
          :label="label.fundingApplication.LabelBusinessName"
          :Value="loanApplicationData.loanBusinessDetails.businessName"
        />

        <LabelValue
          :label="label.fundingApplication.LabelLoanProgram"
          :Value="loanApplicationData.programName"
        />

        <LabelValue
          :label="label.fundingApplication.LabelFundingSource"
          :Value="loanApplicationData.fundingEntityName"
        />
        <strong>
          {{}}
        </strong>
        <LabelValue
        v-if="loanApplicationData.fundingApplication.paymentScheduleSummary !== null"
          :label="label.fundingApplication.LabelLoanAmount"
          :Value="
            String(loanApplicationData.fundingApplication.paymentScheduleSummary.fundAllocatedAmount)
              .replace(/\D/g, '')
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          "
        >
          <template v-slot:prefix><strong>$ </strong></template>
        </LabelValue>

        <LabelValue
          :label="label.fundingApplication.LabelPurposeofFunds"
          :Value="loanApplicationData.fundingApplication.purpose"
        />
        <div
          v-if="
            JSON.stringify(
              loanApplicationData.fundingApplication.programQuestions
            ) != '{}'
          "
        >
          <div
            v-for="question in loanApplicationData.fundingApplication
              .programQuestions"
            :key="question"
          >
            <div v-if="question.response == 'true'">
              <LabelValue :label="question.questionText" Value="Yes" />
            </div>
            <div v-else-if="question.response == 'false'">
              <LabelValue :label="question.questionText" Value="No" />
            </div>
            <div v-else>
              <LabelValue :label="question.questionText" Value="" />
            </div>
          </div>
        </div>
        <div v-else>
          <span> <i> No Questions available</i></span>
        </div>
      </div>
    </div>
    <div class="keep-together" v-if="loanApplicationData.applicationDocuments.length > 0">
      <h2 class="my-3 subHeader">Documents</h2>
      <div
        v-for="document in loanApplicationData.applicationDocuments"
        :key="document"
      >
        <LabelValue :label="document.documentName" :Value="document.fileName" />
      </div>
    </div>
    <div
      class="keep-together"
      v-if="loanApplicationData.showAccountDisbursedInfo == true && loanApplicationData.showAccountDisbursedInfoIfSPA==false"
    >
      <h2 class="my-3 subHeader">Account Disbursed Details</h2>

      <div>
        <LabelValue
          label="Disbursed Amount"
          :Value="
            String(loanApplicationData.fundedAmount)
              .replace('$', '')
              .replace(',', '')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          "
        >
          <template v-slot:prefix><strong class="">$ </strong></template>
        </LabelValue>
        <LabelValue
          label="Transaction Date"
          :Value="loanApplicationData.fundedDate"
        />
      </div>
    </div>
    <div
      class="keep-together"
      v-if="loanApplicationData.disbursedTransactionDetails.length > 0  && loanApplicationData.showAccountDisbursedInfoIfSPA==true"
    >
      <h2 class="my-3 subHeader">Account Disbursed Details</h2>

      <div v-for="acc in loanApplicationData.disbursedTransactionDetails" :key="acc"> 
                <LabelValue
                  :label="acc.disbursementName"
                  :Value="acc.amount"
                />
                <LabelValue
                  label="Disbursed Date"
                  :Value="acc.disbursedDate"
                />
    </div>
    </div>

    <div
      class="keep-together"
      v-if="loanApplicationData.pendingTransactionDetails.length > 0 "
    >
      <h2 class="my-3 subHeader">Pending Payment Details</h2>

      <div v-for="acc in loanApplicationData.pendingTransactionDetails" :key="acc"> 
                <LabelValue
                  :label="acc.disbursementName"
                  :Value="acc.amount"
                />
                <LabelValue
                  label="Transaction Date"
                  :Value="acc.transactionDate"
                />  
    </div>
    </div>

    <div
      class="keep-together"
      v-if="loanApplicationData.showUserAgreementdetailsInfo == true || loanApplicationData.showUserSPAdetailsInfo === true"
    >
    <h2 class="my-3 subHeader">User Agreement</h2>    
      <div v-if="loanApplicationData.showUserAgreementdetailsInfo === true">
          <LabelValue
            label="Agreement Name"
            :Value="loanApplicationData.grantAgreementName"
          />
        <LabelValue
          label="Accepted by"
          :Value="loanApplicationData.agreementAcceptedBy"
        />
        <LabelValue
          label="Acceptance Date"
          :Value="loanApplicationData.agreementAcceptanceDateTime"
        />
      </div>
      <div v-if="loanApplicationData.showUserSPAdetailsInfo == true">
          <LabelValue
            label="Agreement Name"
            :Value="loanApplicationData.spaName"
          />
        <LabelValue
          label="Accepted by"
          :Value="loanApplicationData.spaAcceptedBy"
        />
        <LabelValue
          label="Acceptance Date"
          :Value="loanApplicationData.spaAcceptanceDateTime"
        />
      </div>
    </div>
  </div>
</template>
<script>
import LabelValue from "@/components/LabelValue";
import textStore from "@/textStore.js";
import axios from "axios";
export default {
  components: {
    LabelValue,
  },
  data() {
    return {
      label: textStore,
      loanApplicationData: {},
      defaultAmount: " 10,000",
    };
  },
  setup() {
    return {
      textStore,
    };
  },
  props: {
    applicationID: {
      type: Number,
      default: 0,
    },
  },
  beforeMount() {
    let config = {
      params: {
        applicationID: parseInt(this.applicationID),
      },
    };
    this.$store.dispatch("updateLoaderFlag", true);
    axios
      .get("/LoanApplication/GetApplicationSummary", config)
      .then((res) => {
        if (res.status === 200) {
          this.loanApplicationData = res.data.loanApplication;
          this.$store.dispatch("updateLoaderFlag", false);
        }
      })
      .catch((err) => {
        this.$store.dispatch("updateLoaderFlag", false);
        Promise.reject(err);
      });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.review {
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  h2 {
    font-size: 2.6rem;
    color: $primary-color-black;
  }
  h1 {
    font-size: 2.8rem;
    color: $primary-color-black;
  }
  .row {
    padding-right: 10rem;
    margin: 0;
    p {
      color: $primary-color-black;
      font-size: 1.8rem;
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
    }
    .col-4 {
      p {
        color: $tertiary-color-black;
      }
    }
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
  .subHeader {
    background: #eee 0 0 no-repeat padding-box;
    padding-top: 0.5rem;
  }
  .keep-together {
    page-break-inside: avoid;
  }
}

.logo {
  height: 5rem;
  img {
    height: 100%;
    margin-right: 2rem;
  }
}

hr {
  margin-top: 0.5rem;
  border: 0;
  border-top: 1px solid $primary-color-red;
}
</style>
